<template>
                <div class="container">
                    <div v-if="showForm" class="row no-gutter">
                        <div class="col p-0">
                            <div class="claim-form mt-4" style="    margin: 0 auto;">
                                <div ref="formHeader" class="claim-form__header">
                                    <div>
                                        <h2 class="claim-form__title">Claim Form</h2>
                                        <p class="claim-form__subtitle claim-form__subtitle--light">Start your claim today</p>
                                    </div>

                                    <div class="claim-form__icon">
                                        <img :src="imgurl" alt=""/>
                                    </div>
                                </div>
                                <div class="claim-form__body">
                                    <form-wizard
                                            title=""
                                            subtitle=""
                                            stepSize="xs"
                                            color="#a51f23"
                                            finishButtonText="Submit"
                                            @on-complete="submitForm"
                                            @on-validate="handleValidation"
                                            @on-change="handleWizardChange">
                                        <tab-content title="Your Details" :before-change="validateFormTab1">
                                            <!-- page 1 -->
                                            <FormulateForm ref="formTab1" name="formTab1">
                                                <ul class="claim-form__ul" type="none">
                                                    <li class="claim-form__section section">
                                                        <div class="row no-gutter w-100">
                                                            <div class="col-12 col-sm-6">
                                                                <FormulateInput
                                                                        type="text"
                                                                        class="w-100"
                                                                        name="firstName"
                                                                        label="First Name"
                                                                        validation="bail|required"
                                                                        help="First"
                                                                />
                                                            </div>
                                                            <div class="col-12 pl-sm-4 col-sm-6">
                                                                <FormulateInput

                                                                        type="text"
                                                                        class="w-100 label-transparent"
                                                                        label="Last Name"
                                                                        validation="bail|required"
                                                                        name="lastName"
                                                                        help="Last"
                                                                />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="group"
                                                                name="address"
                                                                class="w-100"
                                                                label="Address"
                                                                validation="bail|required">
                                                            <div class="row no-gutter">
                                                                <div class="col-12 mb-3">
                                                                    <FormulateInput
                                                                            type="text"
                                                                            class="w-100"
                                                                            name="streetName"
                                                                            validation="bail|required"
                                                                            help="Street Address"/>
                                                                </div>
                                                                <div class="col-12 col-sm-6 mb-3">
                                                                    <FormulateInput
                                                                            type="text"
                                                                            class="w-100"
                                                                            name="suburb"
                                                                            validation="bail|required"
                                                                            help="Suburb"/>
                                                                </div>
                                                                <div class="col-12 col-sm-6 pl-sm-4 mb-3">
                                                                    <FormulateInput
                                                                            type="select"
                                                                            class="w-100"
                                                                            name="state"
                                                                            :options="stateOptions"
                                                                            validation="bail|required"
                                                                            help="State"/>
                                                                </div>
                                                                <div class="col-6 mb-3">
                                                                    <FormulateInput
                                                                            type="text"
                                                                            name="postcode"
                                                                            validation="bail|required"
                                                                            help="Post Code"/>
                                                                </div>
                                                            </div>
                                                        </FormulateInput>

                                                    </li>

                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="email"
                                                                label="Email"
                                                                name="email"
                                                                validation="bail|required|email"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="tel"
                                                                label="Phone"
                                                                name="phone"
                                                                validation="bail|required"
                                                        />
                                                    </li>

                                                </ul>
                                            </FormulateForm>
                                        </tab-content>

                                        <tab-content title="Your Vehicle" :before-change="validateFormTab2">
                                            <!-- page 2 -->
                                            <FormulateForm ref="formTab2" name="formTab2">
                                                <ul class="claim-form__ul" type="none">
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="text"
                                                                label="Rego Number"
                                                                name="rego"
                                                                validation="required"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="text"
                                                                label="Make"
                                                                name="make"
                                                                validation="required"

                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="text"
                                                                label="Model"
                                                                name="model"
                                                                validation="required"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="number"
                                                                label="Year of Manufacture"
                                                                name="dom"
                                                                validation="required"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="select"
                                                                label="Are you the registered owner?"
                                                                name="registeredOwner"
                                                                :options="{true: 'Yes', false: 'No'}"
                                                                validation="required"
                                                                v-model="vehicleDetails.registeredOwner"
                                                        />
                                                    </li>
                                                    <li v-if="vehicleDetails.registeredOwner === true || vehicleDetails.registeredOwner === 'true'" class="claim-form__section section">
                                                        <FormulateInput
                                                                type="select"
                                                                label="Do you have any other vehicles registered in your name?"
                                                                name="haveOtherVehicles"
                                                                :options="{true: 'Yes', false: 'No'}"
                                                                validation="required"
                                                                v-model="vehicleDetails.haveOtherVehicles"
                                                        />
                                                    </li>
                                                    <li v-if="vehicleDetails.haveOtherVehicles === true || vehicleDetails.haveOtherVehicles === 'true'" class="claim-form__section section">
                                                        <FormulateInput
                                                                type="text"
                                                                label="Why can you not use this vehicle during the repair?"
                                                                name="whyCannotUseOther"
                                                                validation="required"
                                                        />
                                                    </li>
                                                </ul>
                                            </FormulateForm>
                                        </tab-content>

                                        <tab-content title="Accident Details" :before-change="validateFormTab3">
                                            <!-- page 3 -->
                                            <FormulateForm ref="formTab3" name="formTab3">
                                                <ul class="claim-form__ul" type="none">
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="date"
                                                                name="date"
                                                                label="Date & Time of Accident"
                                                                placeholder="DD/MM/YYYY"
                                                                help="DD/MM/YYYY"
                                                                validation="bail|required"
                                                                format="DD/MM/YYYY"
                                                                min="2018-12-01"
                                                                max="2031-01-01"
                                                                v-model="accidentDetails.date"
                                                        />
                                                    </li>

                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                v-model="accidentDetails.fault"
                                                                :options="{otherParty: 'Other party', me: 'Me'}"
                                                                type="radio"
                                                                label="Who was at fault in the accident"
                                                        />
                                                    </li>

                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="textarea"
                                                                name="description"
                                                                label="Accident Description"
                                                                rows="6"
                                                                validation="bail|required"
                                                                help="Describe how the accident happened"
                                                        />
                                                    </li>

                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="group"
                                                                name="address"
                                                                class="w-100"
                                                                validation="required">
                                                            <FormulateInput
                                                                    type="text"
                                                                    label="Location of Accident"
                                                                    help="Street Address"
                                                                    class="w-100"
                                                                    validation="bail|required"
                                                                    name="streetName"
                                                            />
                                                            <div class="row no-gutter">
                                                                <div class="col-12 col-sm-6 mb-3">
                                                                    <FormulateInput
                                                                            type="text"
                                                                            name="suburb"
                                                                            validation="bail|required"
                                                                            help="Suburb"
                                                                    />
                                                                </div>
                                                                <div class="col-12 col-sm-6 pl-sm-4 mb-3">
                                                                    <FormulateInput
                                                                            type="select"
                                                                            class="w-100"
                                                                            name="state"
                                                                            :options="stateOptions"
                                                                            validation="bail|required"
                                                                            help="State"
                                                                    />
                                                                </div>
                                                                <div class="col-6 mb-3">
                                                                    <FormulateInput
                                                                            type="text"
                                                                            name="postcode"
                                                                            validation="bail|required"
                                                                            help="Post code"/>
                                                                </div>
                                                            </div>
                                                        </FormulateInput>
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="radio"
                                                                label="Were you injured in the Accident"
                                                                name="injured"
                                                                :options="{true: 'Yes', false: 'No'}"
                                                                validation="bail|required"
                                                                v-model="accidentDetails.injured"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section" v-if="accidentDetails.injured === true || accidentDetails.injured === 'true' ">
                                                        <FormulateInput
                                                                type="select"
                                                                label="Would you like us to contact you regarding compensation for any injuries you incurred"
                                                                name="contactForInjuries"
                                                                :options="{true: 'Yes', false: 'No'}"
                                                                validation="bail|required"
                                                                v-model="accidentDetails.contactForInjuries"
                                                        />
                                                    </li>
                                                </ul>
                                            </FormulateForm>
                                        </tab-content>

                                        <tab-content title="At Fault Party's Details" :before-change="validateFormTab4">
                                            <!-- Page 4 -->
                                            <FormulateForm ref="formTab4" name="formTab4">
                                                <ul class="claim-form__ul" type="none">
                                                    <li class="claim-form__section section">
                                                        <div class="row no-gutter w-100">
                                                            <div class="col-12 col-sm-6">
                                                                <FormulateInput
                                                                        type="text"
                                                                        class="w-100"
                                                                        name="driverFirstName"
                                                                        validation="required"
                                                                        help="First"
                                                                        label="At fault driver name"
                                                                />
                                                            </div>
                                                            <div class="col-12 col-sm-6 pl-sm-4">
                                                                <FormulateInput
                                                                        type="text"
                                                                        class="w-100 label-transparent"
                                                                        name="driverLastName"
                                                                        label="last name"
                                                                        validation="required"
                                                                        help="Last"
                                                                />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="tel"
                                                                label="Phone"
                                                                name="phone"
                                                                validation="required"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="text"
                                                                label="Rego"
                                                                name="rego"
                                                                validation="required"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="select"
                                                                label="Their Insurer"
                                                                :options="{aami: 'AAMI', allianz: 'ALLIANZ', budget: 'BUDGET', nrma: 'NRMA', qbe: 'QBE', suncorp: 'SUNCORP', youi: 'YOUI', other: 'Other'}"
                                                                name="their-insurer"
                                                                validation="required"
                                                                v-model="faultPartyDetails.theirInsurer"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section" v-if="faultPartyDetails.theirInsurer === 'other'">
                                                        <FormulateInput
                                                                type="text"
                                                                label="Other Insurer"
                                                                name="otherInsurerName"
                                                                validation="required"
                                                                help="Add the name of the Insurer you use here."
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="text"
                                                                label="Their Insurance claim number"
                                                                name="claimNumber"
                                                                validation="required"
                                                        />
                                                    </li>
                                                </ul>
                                            </FormulateForm>
                                        </tab-content>

                                        <tab-content title="Reasons for Hire" :before-change="validateFormTab5">
                                            <!-- page 5 -->
                                            <FormulateForm ref="formTab5" name="formTab5">
                                                <ul class="claim-form__ul" type="none">
                                                    <li class="claim-form__section section">
                                                        <h2>Reasons For Hire</h2>
                                                        <p>I require a replacement bike for the following reasons:</p>
                                                    </li>

                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="radio"
                                                                label="I am a delivery rider"
                                                                name="delivery-rider"
                                                                :options="{true: 'Yes', false: 'No'}"
                                                                v-model="hireReasons.deliveryRider"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section" v-if="hireReasons.deliveryRider == 'true'">
                                                        <FormulateInput
                                                                type="text"
                                                                label="What delivery company do you work for"
                                                                name="deliveryCompany"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="radio"
                                                                label="I commute to and from work"
                                                                name="commute-to-work"
                                                                :options="{true: 'Yes', false: 'No'}"
                                                                v-model="hireReasons.commuteToWork"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section" v-if="hireReasons.commuteToWork == 'true'">
                                                        <FormulateInput
                                                                type="text"
                                                                label="What company do you work for"
                                                                name="workCompany"

                                                        />
                                                    </li>
                                                    <li class="claim-form__section section" v-if="hireReasons.commuteToWork == 'true'">
                                                        <FormulateInput
                                                                type="text"
                                                                label="What is the address"
                                                                name="workCompanyAddress"

                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="radio"
                                                                label="I commute to and from university / college"
                                                                name="commute-to-college"
                                                                :options="{true: 'Yes', false: 'No'}"
                                                                v-model="hireReasons.commuteToCollege"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section" v-if="hireReasons.commuteToCollege == 'true'">
                                                        <FormulateInput
                                                                type="text"
                                                                label="What is the name of the institute"
                                                                name="collegeName"

                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="radio"
                                                                label="I agree that public transport is not a suitable alternative"
                                                                name="public-transport-not-suitable"
                                                                :options="{true: 'Yes', false: 'No'}"
                                                                v-model="hireReasons.publicTransportNotSuitable"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="checkbox"
                                                                label="I use my bike as it offers the convenience of:"
                                                                name="use-my-bike-because"
                                                                :options="{
                                                parking: 'Motorbike parking',
                                                busLanes: 'Use of bus lanes',
                                                laneSplit: 'Ability to lane split',
                                                cheaperTolls: 'Cheaper tolls',
                                                cheaperPetrol: 'Cheaper petrol',
                                            }"
                                                                v-model="hireReasons.useMyBikeBecause"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="checkbox"
                                                                label="I require a similar bike to my own for the following reasons:"
                                                                name="require-similar-bike-reasons"
                                                                :options="{
                                                safety: 'Safety',
                                                powerAndBraking: 'Familiarity of power & braking',
                                                carrying: 'Carrying pillions'
                                            }"
                                                                v-model="hireReasons.requireSimilarBikeReasons"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="radio"
                                                                label="I agree that a car would be an unsuitable replacement for me"
                                                                name="car-is-unsuitable"
                                                                :options="{true: 'Yes', false: 'No'}"
                                                                v-model="hireReasons.carIsUnsuitable"
                                                        />
                                                    </li>
                                                </ul>
                                            </FormulateForm>
                                        </tab-content>
                                        <tab-content title="Complete Application" :before-change="validateFormTab6">
                                            <!-- page 6 -->
                                            <FormulateForm ref="formTab6" name="formTab6">
                                                <ul class="claim-form__ul" type="none">
                                                    <li class="claim-form__section section">
                                                        <h2>Complete Application</h2>
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="radio"
                                                                label="Is your bike in a rideable condition"
                                                                name="rideable-condition"
                                                                :options="{true: 'Yes', false: 'No'}"
                                                                v-model="completeApplication.rideableCondition"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="radio"
                                                                label="I require a replacement bike as soon as possible"
                                                                name="commute-to-college"
                                                                :options="{true: 'Yes', false: 'No'}"
                                                                v-model="completeApplication.asSoonAsPossible"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section" v-if="completeApplication.asSoonAsPossible">
                                                        <FormulateInput
                                                                type="date"
                                                                name="replacementBikeDate"
                                                                label="Do you have a preferred date you require a replacement bike"
                                                                help="dd/mm/yyyy"
                                                                validation="required|after:2019-01-01"
                                                                min="2018-12-01"
                                                                max="2031-01-01"
                                                                error-behavior="live"
                                                                v-model="completeApplication.replacementBikeDate"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="text"
                                                                label="Who is your repairer?"
                                                                help="Add your Repairers Business name"
                                                                name="repairerName"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="checkbox"
                                                                label="How did you hear about us"
                                                                name="public-transport-not-suitable"
                                                                :options="{
                                                repairer: 'Repairer',
                                                online: 'Online',
                                                tv: 'TV',
                                                towing: 'Towing Company',
                                                other: 'Other'
                                            }"
                                                                v-model="completeApplication.hearAboutUs"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="radio"
                                                                label="Are you lodging a claim with your own insurance company?"
                                                                name="own-insurance-company"
                                                                :options="{true: 'Yes', false: 'No'}"
                                                                v-model="completeApplication.ownInsuranceCompany"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="text"
                                                                label="Provide your claim number"
                                                                name="claimNumber"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="text"
                                                                label="Your Insurance Company name"
                                                                name="insuranceCompanyName"

                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <FormulateInput
                                                                type="radio"
                                                                label="I confirm the information i have provided is true"
                                                                validation="required"
                                                                name="information-is-true"
                                                                :options="{true: 'Yes', false: 'No'}"
                                                                v-model="completeApplication.informationIsTrue"
                                                        />
                                                    </li>
                                                    <li class="claim-form__section section">
                                                        <div class="formulate-input-wrapper">
                                                            <label class="formulate-input-label formulate-input-label--before font-16 font-nunito--bold {">Your Signature <span
                                                                    style="color: red;">*</span></label>
                                                            <div class="claim-form__sign-wrapper">
                                                                <VueSignaturePad
                                                                        id="signature"
                                                                        width="100%"
                                                                        height="208px"
                                                                        ref="signaturePad"
                                                                        v-model="completeApplication.signature"
                                                                        :options="{ penColor: '#1C1F39', onBegin, onEnd}"
                                                                />
                                                            </div>
                                                            <a href="#" class="font-16 font-nunito-bold mt-2 d-inline-block" @click.prevent="clearSignature">Clear</a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </FormulateForm>
                                        </tab-content>
                                        <template slot="finish">
                                            <div>
                                                <button @click="submitForm" class="wizard-btn finish-button submit">
                                                    <div v-if="isSending" class="spinner"></div>
                                                    <span v-if="!isSending">Submit</span></button>
                                                <div class="sr-legal-text">
                                                </div>
                                            </div>
                                        </template>
                                    </form-wizard>
                                </div>
                                <div class="claim-form__footer d-none">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="showSuccess" style="position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%);">
                        <h2 style="text-align: center;"></h2>
                        <label class="success-form">
                            <p>Dear {{claimantDetails.firstName}} {{claimantDetails.lastName}},</p>
                            <br>
                            <p>Thank you for submitting your application for a replacement bike.</p>
                            <div><br></div>
                            <p>The next step is for us to confirm some insurance details so we can provide you with the replacement bike at no cost to yourself.</p>
                            <div><br></div>
                            <p>This process generally takes no more than 1 business day.</p>
                            <div><br></div>
                            <p>Once we have confirmed the insurance details we will send you a notification email which will request your ID and license documents.</p>
                            <div><br></div>
                            <p>We will send you a confirmation email once your application is approved and will organize the delivery of your replacement bike.</p>
                            <div><br></div>
                            <p>Regards,<br></p>
                            <p>The Your Right to Ride Team<br></p>
                            <p style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 8pt; margin-left: 0cm">1300 507 933<br></p>
                            <p style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 8pt; margin-left: 0cm">
                                <a style="color: rgb(5, 99, 193);" href="http://www.yourrighttiride.com.au/">www.yourrighttiride.com.au</a></p>
                            <p style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 8pt; margin-left: 0cm">
                                <a style="color: rgb(5, 99, 193);" href="mailto:info@yourrighttoride.com.au" >info@yourrighttoride.com.au</a></p>
                        </label>
                    </div>
                    <div v-if="computedShowNotClient" style="position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%);">
                        <h2 style="text-align: center;">Your Right To Ride will be unable to help you at this time as the at fault driver.
                            Contact your insurance company for further assistance.</h2>
                        <h4 style="text-align: center; color: #444444;"><a href="http://yourrighttoride.com.au/" style="color: #444444;">Go to main page</a></h4>
                    </div>
                </div>
</template>

<script>

    import {appConfig} from '@/config.js';
    import Axios from 'axios';
    import {mapGetters} from "vuex";

    export default {
    name: 'claim-form-yrtr',
    data() {
        return {
            isSending: false,
            testForm3: {},
            showSuccess: false,
            showForm: true,
            showNotClient: false,
            behaviorTab1: 'blur',
            validation: {},
            keyForm: appConfig.keyForm,
            claimantDetails: {
                "firstName": "",
                "lastName": "",
                "address": {
                    "streetName": "",
                    "suburb": "",
                    "state": "",
                    "postcode": "",
                },
                "email": "",
                "phone": "",
            },
            vehicleDetails: {
                "rego": "",
                "make": "",
                "model": "",
                "dom": "",
                "registeredOwner": "",
                "haveOtherVehicles": "",
                "whyCannotUseOther": ""
            },
            accidentDetails: {
                "date": "",
                "fault": "",
                "description": "",
                "address": {
                    "streetName": "",
                    "suburb": "",
                    "state": "",
                    "postcode": "",
                },
                "injured": "bool",
                "contactForInjuries": ""
            },
            faultPartyDetails: {
                "driverFirstName": "",
                "driverLastName": "",
                "phone": "",
                "rego": "",
                "theirInsurer": "",
                "otherInsurerName": "",
                "claimNumber": ""
            },
            hireReasons: {
                "deliveryRider": "",
                "deliveryCompany": "",
                "commuteToWork": "",
                "workCompany": "",
                "workCompanyAddress": "",
                "commuteToCollege": "",
                "collegeName": "",
                "publicTransportNotSuitable": "",
                "useMyBikeBecause": {
                    "parking": "",
                    "busLanes": "",
                    "laneSplit": "",
                    "cheaperTolls": "",
                    "cheaperPetrol": ""
                },
                "requireSimilarBikeReasons":{
                    "safety": "",
                    "powerAndBraking": "",
                    "carrying": ""
                },
                "carIsUnsuitable": ""
            },
            completeApplication: {
                "rideableCondition": "bool",
                "replacementBikeDate": "date",
                "asSoonAsPossible": "bool",
                "repairerName": "",
                "hearAboutUs": {
                    "repairer": "bool",
                    "online": "bool",
                    "tv": "bool",
                    "radio": "bool",
                    "towing": "bool",
                    "other": "bool"
                },
                "ownInsuranceCompany": "bool",
                "claimNumber": "",
                "insuranceCompanyName": "",
                "informationIsTrue": "bool",
                "signature": null,
            },
            imgurl: 'https://dev.pay.yourrighttoride.com.au/img/logo.png',

        };
    },
    methods: {
        onBegin(){
        },
        onEnd(){
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            this.completeApplication.signature = data;
        },
        clearSignature() {
            this.completeApplication.signature = null;
            this.$refs.signaturePad.clearSignature();
        },
        handleValidation: function (isValid, tabIndex) {
            console.log('Tab: ' + tabIndex + ' valid: ' + isValid);
        },
        validateFormTab1() {
            if (this._isDebug) {
                return true;
            }
            if (!this.$refs['formTab1']) {
                return true;
            }
            this.$refs['formTab1'].formSubmitted();
            if (!this.$refs['formTab1'].hasErrors) {
                return true;
            }
            return false;
        },
        validateFormTab2() {
            if (this._isDebug) {
                return true;
            }
            if (!this.$refs['formTab2']) {
                return true;
            }
            this.$refs['formTab2'].formSubmitted();
            if (!this.$refs['formTab2'].hasErrors) {
                return true;
            }
            return false;
        },
        validateFormTab3() {
            //console.log(this.$refs['formTab3']);
            console.log(this.$refs['formTab3'].formContext.value);
            if (this._isDebug) {
                return true;
            }
            if (this.accidentDetails.fault !=='otherParty'){
                return false;
            }
            if (!this.$refs['formTab3']) {
                return true;
            }
            this.$refs['formTab3'].formSubmitted();
            if (!this.$refs['formTab3'].hasErrors) {
                this.scrollToHeader();
                return true;
            }
            return false;
        },
        validateFormTab4() {
            if (this._isDebug) {
                return true;
            }
            //this.$formulate.submit('formTab1');
            if (!this.$refs['formTab4']) {
                return true;
            }
            this.$refs['formTab4'].formSubmitted();
            if (!this.$refs['formTab4'].hasErrors) {
                return true;
            }
            return false;
        },
        validateFormTab5() {
            if (this._isDebug) {
                return true;
            }
            //this.$formulate.submit('formTab1');
            if (!this.$refs['formTab5']) {
                return true;
            }
            this.$refs['formTab5'].formSubmitted();
            if (!this.$refs['formTab5'].hasErrors) {
                return true;
            }
            return false;
        },
        validateFormTab6() {
            if (this._isDebug) {
                return true;
            }
            //this.$formulate.submit('formTab1');
            if (!this.completeApplication.signature) {
                return false;
            }
            if (!this.$refs['formTab6']) {
                return true;
            }
            this.$refs['formTab6'].formSubmitted();
            if (!this.$refs['formTab6'].hasErrors) {
                return true;
            }
            return false;
        },
        scrollToHeader() {
            this.$nextTick(() => {
                if (this.$refs['formHeader']) {
                    this.$refs['formHeader'].scrollIntoView();
                }
            });

        },
        handleWizardChange(prevIndex, nextIndex) {
            console.log('prevIndex', prevIndex, 'nextIndex', nextIndex);
            this.scrollToHeader();
            if (nextIndex === 5) {
                // re-calculate signature pad canvas
                this.$nextTick(() => {
                    this.$refs.signaturePad.resizeCanvas();
                });
            }
        },
        submitForm() {
            if (this.isSending) {
                return;
            }
            this.isSending = true;
            let data1 = this.$refs['formTab1'].formContext.value;
            this.claimantDetails.email = data1.email;
            this.claimantDetails.firstName = data1.firstName;
            this.claimantDetails.lastName = data1.lastName;
            this.claimantDetails.phone = data1.phone;
            this.claimantDetails.address.streetName = data1.address[0].streetName;
            this.claimantDetails.address.state = data1.address[0].state;
            this.claimantDetails.address.postcode = data1.address[0].postcode;
            this.claimantDetails.address.suburb = data1.address[0].suburb;

            let data2 = this.$refs['formTab2'].formContext.value;
            this.vehicleDetails.rego = data2.rego;
            this.vehicleDetails.make = data2.make;
            this.vehicleDetails.model = data2.model;
            this.vehicleDetails.dom = data2.dom;
            this.vehicleDetails.registeredOwner = data2.registeredOwner;
            this.vehicleDetails.whyCannotUseOther = data2.whyCannotUseOther;

            let data3 = this.$refs['formTab3'].formContext.value;
            this.accidentDetails.date = data3.date;
            this.accidentDetails.fault = data3.fault;
            this.accidentDetails.description = data3.description;
            this.accidentDetails.injured = data3.injured;
            this.accidentDetails.contactForInjuries = data3.contactForInjuries;
            this.accidentDetails.address.streetName = data3.address[0].streetName;
            this.accidentDetails.address.state = data3.address[0].state;
            this.accidentDetails.address.postcode = data3.address[0].postcode;
            this.accidentDetails.address.suburb = data3.address[0].suburb;

            let data4 = this.$refs['formTab4'].formContext.value;
            this.faultPartyDetails.driverFirstName = data4.driverFirstName;
            this.faultPartyDetails.driverLastName = data4.driverLastName;
            this.faultPartyDetails.phone = data4.phone;
            this.faultPartyDetails.rego = data4.rego;
            this.faultPartyDetails.claimNumber = data4.claimNumber;

            let data5 = this.$refs['formTab5'].formContext.value;
            this.hireReasons.collegeName = data5.collegeName;
            this.hireReasons.deliveryCompany = data5.deliveryCompany;
            this.hireReasons.workCompany = data5.workCompany;
            this.hireReasons.workCompanyAddress = data5.workCompanyAddress;

            let data6 = this.$refs['formTab6'].formContext.value;
            this.completeApplication.claimNumber = data6.claimNumber;
            this.completeApplication.insuranceCompanyName = data6.insuranceCompanyName;
            this.completeApplication.repairerName = data6.repairerName;
            this.completeApplication.replacementBikeDate = data6.replacementBikeDate;

            let data = {
                claimantDetails: this.claimantDetails,
                vehicleDetails: this.vehicleDetails,
                accidentDetails: this.accidentDetails,
                faultPartyDetails: this.faultPartyDetails,
                hireReasons: this.hireReasons,
                completeApplication: this.completeApplication,
                keyForm: this.keyForm,
            };
            Axios.post('/efr/send', data)
                .then(res => {
                    if(res && res.data && res.data._status) {
                        // this.$toast.success('SAVED!');
                        this.showForm = false;
                        this.showSuccess = true;
                    } else if(res.data._errors) {
                        res.data._errors.forEach(e => this.$toast.error(e));
                    }
                })
                .catch(e => console.warn(e))
                .finally(e => {
                    this.isSending = false;
                });
        },
        loadClaimData(){
            Axios.get('/efr/license-form-data/' + this.hash)
                .then(res => {
                    if(res && res.data && res.data._status){
                        this.data.claimNumber = res.data.data.claimNumber;
                        this.data.firstName = res.data.data.firstName;
                        this.data.lastName = res.data.data.lastName;
                    } else {
                        this.showForm = false;
                        this.showError = true;
                    }
                })
                .catch(e => console.warn(e));
        }
    },
        computed: {
            ...mapGetters({
                stateOptions: 'addressStates'
            }),
            _isDebug() {
                if (this.$route.query && this.$route.query._debug && this.$route.query._debug == "true") {
                    return true;
                }
                return false;
            },
            computedShowNotClient() {
                if (this.accidentDetails.fault == 'me') {
                    this.showNotClient = true;
                    this.showForm = false;
                }
                return this.showNotClient;
            },
            computedShowSuccess() {
                return this.showSuccess;
            },
        }
}
</script>

<style lang="scss">
    .claim-form {
        border: 2px solid rgba(27, 30, 56, 0.1);
        border-color: #a51f23;
        border-radius: 8px;
        overflow: hidden;
        max-width: 800px;
        margin-bottom: 2rem;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            background-color: #a51f23;
            padding: 10px 18px;
        }

        &__footer {
            min-height: 80px;
            background-color: #a51f23;
            padding: 10px 18px;
        }

        &__title,
        &__subtitle--light {
            margin: 0;
            color: white;
        }

        &__body {
            padding: 10px;
        }

        &__icon {
            img {
                max-width: 38vw;
            }
        }

        &__ul {
            margin: 0;
            padding: 0;
        }

        &__section {
            margin: 5px 0px 5px 0px;
            padding: 10px;

            h2 {
                padding-bottom: 10px;
                border-bottom: 1px solid rgba(215, 215, 215, 1);
            }

            a {
                color: #a51f23;
            }

            &--cc {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &__cc {
            width: 450px;
        }

        &__cc-inner {
            border-radius: 8px;
            padding: 10px 15px 15px;
            border: 1px solid #D7D7D7;
        }

        &__cc-title {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: normal;
            font-weight: 700;
            margin-bottom: 6px;
        }

        &__sign-wrapper {
            border: 2px solid #D9D9D9;
            border-radius: 13px;
        }
    }

    div.StripeElement {
        border: 2px solid #D9D9D9 !important;
        min-height: 46px !important;
        box-shadow: none !important;
    }

    @media screen and (max-width: 600px) {
        .claim-form {
            &__title {
                font-size: 1.7rem;
            }
        }
    }

</style>

<style lang="scss" scoped>
    .section {
        p {
            color: #847F7F;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .success-form{
        font-size:21px;
        font-weight: 500;
    }
</style>
<style scoped>
    .submit {
        background-color: rgb(165, 31, 35) !important;
        border-color: rgb(165, 31, 35)  !important;
        color: white !important;
    }

    .spinner,
    .spinner:before,
    .spinner:after {
        border-radius: 50%;
    }

    .spinner {
        color: #FFFFFF;
        font-size: 22px;
        text-indent: -99999px;
        margin: 0px auto;
        position: relative;
        width: 20px;
        height: 20px;
        box-shadow: inset 0 0 0 2px;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }

    .spinner:before,
    .spinner:after {
        position: absolute;
        content: "";
    }

    .spinner:before {
        width: 10.4px;
        height: 20.4px;
        background: var(--accent-color);
        border-radius: 20.4px 0 0 20.4px;
        top: -0.2px;
        left: -0.2px;
        -webkit-transform-origin: 10.4px 10.2px;
        transform-origin: 10.4px 10.2px;
        -webkit-animation: loading 2s infinite ease 1.5s;
        animation: loading 2s infinite ease 1.5s;
    }

    .spinner:after {
        width: 10.4px;
        height: 10.2px;
        background: var(--accent-color);
        border-radius: 0 10.2px 10.2px 0;
        top: -0.1px;
        left: 10.2px;
        -webkit-transform-origin: 0px 10.2px;
        transform-origin: 0px 10.2px;
        -webkit-animation: loading 2s infinite ease;
        animation: loading 2s infinite ease;
    }

    @-webkit-keyframes loading {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes loading {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

</style>