export const States = {
    users: {},
    addressStates: {
        NSW: "New South Wales",
        NT: "Northern Territory",
        SA: "South Australia",
        TAS: "Tasmania",
        QLD: "Queensland",
        VIC: "Victoria",
        WA: "Western Australia",
    },
    addressCountries: [
        {key: "AU", value: "Australia"},
        {key: "NZ", value: "New Zealand"},
    ],
    vehicleTransmission: [
        {key: "Automatic", value: "Automatic"},
        {key: "Manual", value: "Manual"},
    ],

}
