<template>
  <div
          class="wrapper theme-claimlinq is-visible"
          v-show="isLoaded"
  >
      <div class="" style="background-color: #FFFFFF; padding:25px;">
          <!-- Content -->
          <div class="content-area">
              <div class="container-fluid">
                  <router-view></router-view>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
    /* eslint-disable */
    import Vue from "vue";
    import VueRouter from 'vue-router';
    import accounting from "accounting";
    import claimFormYrtr from '@/forms/yrtr/claimForm';

    import Axios from "axios";

    Vue.use(VueRouter);

    export default {
    name: "app",
    components: {
        claimFormYrtr,
    },
    data: function () {
      return {
          isLoaded: false,
      };
    },
    computed: {
    },
    methods: {
        loadData(){
            Axios.get('/efr/license-form-data/' + this.hash)
                .then(res => {
                    if(res && res.data && res.data._status){
                        this.data.claimNumber = res.data.data.claimNumber;
                        this.data.firstName = res.data.data.firstName;
                        this.data.lastName = res.data.data.lastName;
                    } else {
                        this.showForm = false;
                        this.showError = true;
                    }
                })
                .catch(e => console.warn(e));
        }
    },
    created: function () {
    },
    watch: {},
    mounted() {
      this.$nextTick(() => {
        this.isLoaded = true;

      });
    }
  };
  Vue.filter("UpperCase", function (value) {
    if (!value) {
      return '';
    }
    return _.upperCase(value);
  });
  Vue.filter("formatMoney", function (value) {
    return accounting.formatMoney(value);
  });
</script>

<style lang="scss">
  .wrapper {
    visibility: hidden;
    opacity: 0;
    transition: opacity 100ms ease-out;
    transition-delay: 200ms;
  }

  .wrapper.is-visible,
  .wrapper.theme-claimlinq,
  .wrapper.theme-supplier,
  .wrapper.theme-home {
    visibility: visible;
    opacity: 1;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2C3E50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2C3E50;

      &.router-link-exact-active {
        color: #42B983;
      }
    }
  }

  .b-form-btn-label-control.form-control > .btn:hover {
    color: green;
  }

  .b-form-btn-label-control.form-control > .form-control {
    padding-top: 9px;
  }

</style>
