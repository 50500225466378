/*eslint-disable */
import Vue from 'vue';
import VueRouter from 'vue-router';

import claimFormYrtr from '@/forms/yrtr/claimForm';
import RequestLicense from '@/forms/yrtr/requestLicense';
import payMyDeposit from '@/forms/yrtr/payMyDeposit';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'claimFormYrtr',
        component: claimFormYrtr,
    },
    {
        path: '/license/:code',
        name: 'YRTR Request License',
        component: RequestLicense
    },
    {
        path: '/pay/:code',
        name: 'YRTR Pay My Deposit',
        component: payMyDeposit
    },
];

const router = new VueRouter({
    routes,
    mode: 'hash'
})


export default router;
