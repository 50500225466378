<template>
    <div class="container">
        <div v-if="showForm" class="row no-gutter">
            <div class="col p-0">
                <div class="claim-form mt-4" style="margin: 0 auto;">
                    <div class="claim-form__header">
                        <h2 class="claim-form__title">Confirm my Credit Card</h2>
                        <div class="claim-form__icon">
                            <img :src="imgurl" alt=""/>
                        </div>
                    </div>

                    <div class="claim-form__body">
                        <ul class="claim-form__ul" type="none">
                            <li class="claim-form__section section">
                                <h2 class="claim-form__subtitle">Claim</h2>
                                <p>
                                    To enable us to complete your replacement
                                    bike processing. Please provide a credit
                                    card we can use for toll and excess charges.
                                </p>
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                        type="text"
                                        label="Claim Number"
                                        help="Your claim reference number associated with your replacement bike."
                                        readonly
                                        v-model="data.claimNumber"/>
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                        type="text"
                                        label="Leasee Name"
                                        help="Your name"
                                        readonly
                                        v-model="data.name"/>
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                        type="text"
                                        label="Bike Rego"
                                        help="Registration of your bike in the claim."
                                        readonly
                                        v-model="data.rego"/>
                            </li>
                            <li v-if="!showSuccess" class="claim-form__section section">
                                <h2 class="claim-form__subtitle">Card Details</h2>
                                <p>Enter your credit card details below.</p>
                                <p>Your Right To Ride will process and hold a copy of your card details for the duration of your lease. The card you provide will be used for payments of tolls, fines
                                    or excess and will be made upon finalisation of the lease.</p>
                            </li>
                            <li v-if="!showSuccess" class="claim-form__section section">
                                <FormulateInput
                                        type="checkbox"
                                        label="I authorise Your Right To Ride to process a copy of my card for payment of charges incurred during the lease period. *"
                                        v-model="agree"/>
                            </li>
                            <li v-if="!showSuccess" class="claim-form__section claim-form__section--cc section" id="cardData">
                                <div class="claim-form__cc sr-payment-form payment-view">
                                    <div class="claim-form__cc-title">Credit Card Details</div>
                                    <div class="claim-form__cc-inner">
                                        <FormulateInput
                                                name="name"
                                                class="w-100"
                                                v-model="cardName"
                                                :disabled="!isAuthorise"
                                                label="Name on card"/>
                                        <div>
                                            <label class="card-number">Card Number</label>
                                            <div class="sr-input sr-card-element" id="card-element"></div>
                                        </div>
                                        <div>
                                            <button @click="pay" id="submitCard" style="padding: 12px 16px;margin-top: 16px;color: white;" :disabled="!isAuthorise">
                                                <div class="spinner hidden" id="spinner"></div>
                                                <span id="button-text">Submit</span>
                                            </button>
                                            <div class="sr-legal-text">
                                            </div>
                                        </div>
                                        <div class="sr-legal-text">
                                        </div>
                                    </div>
                                    <div class="sr-field-error" id="card-errors" role="alert">{{errorMsg}}</div>
                                </div>
                            </li>
                            <li v-if="showSuccess" tabindex="1" class="claim-form__section section"><h2 class="claim-form__subtitle"><span>Payment Completed Successfully</span></h2>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showError" style="position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%);">
            <h2 style="text-align: center;">FORM NOT FOUND</h2>
            <h4 style="text-align: center; color: #444444;"><a href="http://yourrighttoride.com.au/" style="color: #444444;">Go to main page</a></h4>
        </div>
    </div>
</template>

<script>
    //import { StripeElementCard } from '@vue-stripe/vue-stripe';
    import Axios from "axios";
    import '@stripe/stripe-js';

    export default {
        name: 'pay-deposit-form',
        components: {
            //StripeElementCard
        },
        data() {
            return {
                showForm: true,
                showSuccess: false,
                showError: false,
                errorMsg: null,
                card: null,
                cardName: '',
                elementsError: null,
                submittingPaymentMethod: false,
                stripe: null,
                hash: '',
                agree: false,
                imgurl: 'https://dev.pay.yourrighttoride.com.au/img/logo.png',
                data: {
                    claimNumber: '',
                    name: '',
                    rego: '',
                    payId: null,
                    stripe: {},
                },
            };
        },
        created() {
            this.hash = this.$route.params.code;
        },
        mounted() {
            this.loadClaimData();
        },
        computed: {
            isAuthorise() {
                return (this.agree || this.agree === 'true');
            },
        },
        watch: {
            isAuthorise: function (value) {
                if (this.card && value === true) {
                    this.card.update({disabled: false});
                } else if (this.card) {
                    this.card.update({disabled: true});
                }
            },
        },
        methods: {
            initStripe() {
                this.$nextTick(() => {
                    this.createElement();
                });
            },
            orderComplete(clientSecret) {
                stripe.retrievePaymentIntent(clientSecret).then(function (result) {
                    var paymentIntent = result.paymentIntent;
                    var paymentIntentJson = JSON.stringify(paymentIntent, null, 2);
                    document.querySelectorAll(".payment-view").forEach(function (view) {
                        view.classList.add("hidden");
                    });
                    document.querySelectorAll(".completed-view").forEach(function (view) {
                        view.classList.remove("hidden");
                    });
                });
            },
            changeLoadingState(isLoading) {
                if (isLoading) {
                    document.querySelector("button").disabled = true;
                    document.querySelector("#spinner").classList.remove("hidden");
                    document.querySelector("#button-text").classList.add("hidden");
                } else {
                    document.querySelector("button").disabled = false;
                    document.querySelector("#spinner").classList.add("hidden");
                    document.querySelector("#button-text").classList.remove("hidden");
                }
            },
            pay() {
                //var cardholderName = document.querySelector("#name").value;
                let stripe = this.stripe;
                let card = this.card;
                let clientSecret = this.data.stripe.clientSecret;
                let vm = this;
                var data = {
                    card: card,
                    billing_details: {}
                };

                this.changeLoadingState(true);

                stripe
                    .confirmCardPayment(clientSecret, {payment_method: data})
                    .then(function (result) {
                        //console.log('result', result);
                        if (result.error) {
                            vm.changeLoadingState(false);
                            vm.errorMsg = result.error.message;
                            setTimeout(function () {
                                vm.errorMsg = "";
                            }, 4000);
                        } else {
                            vm.saveRusult(result.paymentIntent);
                        }

                        //console.log('result', result);
                    });
            },
            saveRusult(data) {
                let saveData = {
                    claimCode: this.hash,
                    paymentId: data.id,
                };
                Axios.post('/efr/credit-card-form', saveData)
                    .then(res => {
                        if (res && res.data && res.data._status) {
                            this.showSuccess = true;
                            this.changeLoadingState(false);
                        } else {

                        }
                    })
                    .catch(e => console.warn(e));
            },
            loadClaimData() {
                Axios.get('/efr/credit-card-form/' + this.hash)
                    .then(res => {
                        if (res && res.data && res.data._status) {
                            this.showForm = true;
                            this.showError = false;
                            this.data.claimNumber = res.data.data.claimNumber;
                            this.data.name = res.data.data.fullName;
                            this.data.rego = res.data.data.rego;
                            if (res.data.data.stripe) {
                                this.data.stripe = res.data.data.stripe;
                                this.$nextTick(() => {
                                    this.stripe = Stripe(this.data.stripe.publicKey);
                                    this.initStripe();
                                });
                            }
                            if (res.data.data.payId && res.data.data.payId != '')
                            {
                                this.showSuccess = true;
                            }
                        } else {
                            this.showForm = false;
                            this.showError = true;
                        }
                    })
                    .catch(e => console.warn(e));
            },
            createElement() {
                if (!this.stripe) {
                    return;
                }
                const elements = this.stripe.elements({
                    // Use Roboto from Google Fonts
                    fonts: [
                        {
                            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
                        },
                    ],
                    // Detect the locale automatically
                    locale: 'auto',
                });
                // Define CSS styles for Elements
                const style = {
                    base: {
                        color: "#32325D",
                        height: "30px",
                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                        fontSmoothing: "antialiased",
                        fontSize: "16px",
                        "::placeholder": {
                            color: "#AAB7C4"
                        }
                    },
                    // Styles when the Element has invalid input
                    invalid: {
                        color: "#FA755A",
                        iconColor: "#FA755A"
                    }
                };
                // Create the card element, then attach it to the DOM
                this.card = elements.create("card", {hidePostalCode: true, style: style});
                this.card.mount('#card-element');
                // Add an event listener: check for error messages as we type
                this.card.addEventListener('change', ({error}) => {
                    if (error) {
                        this.elementsError = error.message;
                    } else {
                        this.elementsError = '';
                    }
                });
                this.card.update({disabled: true});
            },
        },
        beforeDestroy() {
            if (this.card) {
                this.card.unmount();
                this.card = null;
            }
        },
    };

</script>

<style lang="scss">
    .claim-form {
        border: 2px solid rgba(27, 30, 56, 0.1);
        /*border-color: #00b050;*/
        border-radius: 8px;
        overflow: hidden;
        max-width: 800px;
        margin-bottom: 2rem;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            /*background-color: #00b050;*/
            padding: 10px 18px;
        }

        &__footer {
            min-height: 80px;
            /*background-color: #00b050;*/
            padding: 10px 18px;
        }

        &__title,
        &__subtitle--light {
            margin: 0;
            color: white;
        }

        &__body {
            padding: 10px;
        }

        &__icon {
            img {
                max-width: 38vw;
            }
        }

        &__ul {
            margin: 0;
            padding: 0;
        }

        &__section {
            margin: 5px 0px 5px 0px;
            padding: 10px;

            h2 {
                padding-bottom: 10px;
                border-bottom: 1px solid rgba(215, 215, 215, 1);
            }

            a {
                color: #00B050;
            }

            &--cc {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &__cc {
            width: 450px;
        }

        &__cc-inner {
            border-radius: 8px;
            padding: 10px 15px 15px;
            border: 1px solid #D7D7D7;
        }

        &__cc-title {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: normal;
            font-weight: 700;
            margin-bottom: 6px;
        }

        &__sign-wrapper {
            border: 2px solid #D9D9D9;
            border-radius: 13px;
        }
    }

    div.StripeElement {
        border: 2px solid #D9D9D9 !important;
        min-height: 46px !important;
        box-shadow: none !important;
    }

    @media screen and (max-width: 600px) {
        .claim-form {
            &__title {
                font-size: 1.7rem;
            }
        }
    }

</style>

<style lang="scss" scoped>
    .section {
        p {
            color: #847F7F;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    label.card-number {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: normal;
        font-weight: 700;
        margin-bottom: 6px;
    }
</style>

<style lang="css" scoped>
    @import "../../styles/pay-my-deposit/normalize.css";
    @import "../../styles/pay-my-deposit/global.css";
</style>